import React from 'react';
import {Link} from 'gatsby';
import {FooterMenuWrap, FooterMenuList} from './footer-menu.stc'

const FooterMenu = () => {
    return (
        <FooterMenuWrap>
            <FooterMenuList>
                <Link to="/" className="no-cursor">Партнёры</Link>
            </FooterMenuList>
            <FooterMenuList>
                <Link to="/" className="no-cursor">Конфиденциальность</Link>
            </FooterMenuList>
            <FooterMenuList>
                <Link to="/" className="no-cursor">Cookies</Link>
            </FooterMenuList>
            <FooterMenuList>
                <Link to="/" className="no-cursor">Услуги</Link>
            </FooterMenuList>
            <FooterMenuList>
                <Link to="/" className="no-cursor">Картасайта</Link>
            </FooterMenuList>
            <FooterMenuList>
                <Link to="/" className="no-cursor">Пресса</Link>
            </FooterMenuList>
            <FooterMenuList>
                <Link to="/" className="no-cursor">Вакансии</Link>
            </FooterMenuList>
        </FooterMenuWrap>
    )
}

export default FooterMenu;